<template>
<div class="about">
  <h1>About Me</h1>
  <p>this is where i write my bio</p>
  <p>this is where i write my bio</p>
  <p>this is where i write my bio</p>
  <p>this is where i write my bio</p>
  <p>this is where i write my bio</p>
  <p>this is where i write my bio</p>
</div>
</template>
